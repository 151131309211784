<template>
  <!-- <swiper ref="mySwiper" :options="swiperOption"> -->
  <!-- <swiper-slide class="swiper-slide" v-for="item in slide" :key="item.id">
      <div class="one-pic">
            <div class="img">
              <img :src="item.imgOne" :alt="item.contentOne"  style="width:100%;height:100%">
           </div>
           <div class="info"> {{item.contentOne}}</div>
      </div>
      <div class="one-pic" style="margin-left:2%">
         <div  class="img">
           <img :src="item.imgTwo" :alt="item.contentTwo"  style="width:100%;height:100%">
          </div>
          <div class="info">  {{item.contentTwo}}</div>
      </div>
      <div class="one-pic" style="margin-left:2%">
         <div  class="img">
           <img :src="item.imgThree" :alt="item.contentThree"  style="width:100%;height:100%">
          </div>
          <div class="info">  {{item.contentThree}}</div>
      </div>
    </swiper-slide> -->
  <!-- <div class="swiper-pagination"  slot="pagination"></div>   分页 -->
  <!-- <div class="swiper-button-prev" slot="button-prev"></div>  箭头左  -->
  <!-- <div class="swiper-button-next" slot="button-next"></div>  箭头右  -->
  <el-col>
    <div class="grid-content bg-purple" id="parent">
      <el-carousel style="height: 3.16176rem;">
        <el-carousel-item
          class="swiper-slide"
          v-for="(item,index) in slide[0]"
          :key="index"
        >
          <div class="one-pic" v-for="(item2,index2) in item" :key="index2">
            <!-- <font style="position:absolute;bottom:21px;left:15px;display:inline-block;letter-space:1px;"
             color="#cccccc">{{item2.content}}
            </font> -->
            <div class="img">
              <img
                :src="concatURL(item2.img)"
                alt="加载中(*>∀＜*)"
                style="width: 100%; height: 100%"
                @click="$router.push('/writeanswer?id='+item2.content)"
              />
            </div>
            <!-- <div class="info"> {{item.contentOne}}</div> -->
          </div>
           <!-- <div class="one-pic" style="margin-left: 2%">
            <div class="img">
              <img
                :src="item.imgTwo"
                :alt="item.contentTwo"
                style="width: 100%; height: 100%"
              />
            </div> -->
            <!-- <div class="info">  {{item.contentTwo}}</div> -->
          <!-- </div> -->
          <!-- <div class="one-pic" style="margin-left: 2%">
            <div class="img">
              <img
                :src="item.imgThree"
                :alt="item.contentThree"
                style="width: 100%; height: 100%"
              />
            </div> -->
            <!-- <div class="info">  {{item.contentThree}}</div> -->
          <!-- </div>  -->
        </el-carousel-item>
      </el-carousel>
    </div>
  </el-col>
  <!-- </swiper> -->
</template>

<script>
import { QUERYED } from "@/services/dao.js";
export default {
  data() {
    return {
      slide: [],
      //设置属性
      swiperOption: {
        //显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //允许分页点击跳转
        },
        //设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        //自动轮播
        autoplay: {
          delay: 1000,
        },
        //开启循环模式
        loop: true,
        //开启鼠标滚轮控制Swiper切换
        mousewheel: false,
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    //初始化
    init() {
      this.initSlide();
    },
    //初始化轮播图数据
    async initSlide() {
      this.slide = [];
      var result = [];
      // let info= await QUERYED("post","",'WDRotation {  id   imgOne     contentOne     contentThree     contentTwo     imgThree     imgTwo     orderNum     questionOne     questionThree     questionTwo   }');
      let info = await QUERYED(
        "post",
        "",
        "TtCarousel(where: {type:{_eq:" +
          1 +
          "}}) {id img content} TtCarousel_aggregate(where:{type:{_eq:" +
          1 +
          "}}) {aggregate { count }} "
      );
      console.log("slide: ", info);
      var data = info.data.TtCarousel;
      // 对返回数据 分3条一组
      for(let i=0;i<data.length;i+=3){
        result.push(data.slice(i,i+3));
      }
      console.log('result: ',result);
      this.slide.push(result);
      console.log("this.slide: ",this.slide);
     
      // info.data.TtCarousel.forEach((element) => {
      //   this.slide.push(element);
      // });
    },
    // 连接imgURL
    concatURL(val){
      // console.log("concat--val: ",val)
      return this.api.LoginURL.concat(val);
    },
    //初始化专业团队数据
    // initTeam(){

    // },
    //初始化业界专家数据
    // initExport(){

    // }
    //初始化热议问题
  },
};
</script>

<style>
.swiper-pagination-bullet-active {
  background-color: white;
}
.one-pic {
  width: 32%;
  height: 100%;
  display: inline-block;
  /* background-color: red; */
  padding-right: 8px;
  position: relative;
}
.one-pic .img {
  width: 100%;
  /* border: 1px solid yellowgreen; */
  height: 100%;
}
.one-pic .info {
  height: 20%;
  background-color: rgba(238, 238, 238, 1);
  padding: 5px 10px;
  text-align: left;
}
.swiper-slide {
  display: inline-block;
  height: 300px;
  /* border: 1px solid red; */
}
.info {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: #000000;
  line-height: 22px;
}

/* reset css */
/* .grid-content,.bg-purple >>> .el-carouselel-carousel,.el-carousel--horizontal{
  
} */
</style>
