<template>
  <div class="content">
    <div class="up">
      <!-- 轮播 -->
      <div class="slide">
        <V-Swiper style="height:100%"></V-Swiper>
      </div>
      <!-- 模块介绍 -->
      <div class="sys-intro">
        <div class="info">
          <span class="bold">“智能问答”</span>是专业内容知识服务智荟网的一个版块，是专注于学术/科研/前沿技术问答交易的平台，欢迎您的加入，共同开启更多精彩
        </div>
        <div class="group">
          <div class="answer">
            <div class="answer-bg">
              <span class="answer-icon"></span>
            </div>
            <div>回答问题</div>
          </div>
          <div class="question">
            <div class="question-bg">
              <span class="question-icon"></span>
            </div>
            <div>提出问题</div>
          </div>
        </div>
      </div>
    </div>
    <div class="down">
      <div class="left">
        <div class="left-label">
          <span>最新问题</span>
        </div>
        <div class="items">
          <div class="item" v-for="oneQuestion in questionList" :key="oneQuestion.id">
            <V-OneQuestion :onequestion="oneQuestion"></V-OneQuestion>
          </div>
        </div>
        <!-- 分页 -->
        <div class="page-nav">
          <!-- <el-pagination
                        background
                        layout="prev, pager, next"
                        :hide-on-single-page="hidePageNav"
                        :total="totlePage"
                        :page-size="pageSize"
                        @prev-click="prevPage" @next-click="nextPage"  @current-change="currentPage">
          </el-pagination>-->
        </div>
      </div>
      <div class="right">
        <!-- <div class="team">
          <div class="t-label">
            <span class="team-bg">
              <span class="team-icon"></span>
            </span>
            <span class="Ranking">专业团队</span>
          </div>
          <V-AnswererLists :list="teamList"></V-AnswererLists>
        </div>-->
        <!-- <div class="team expert">
          <div class="t-label">
            <span class="team-bg">
              <span class="team-icon"></span>
            </span>
            <span class="Ranking">业界专家</span>
          </div>
          <V-AnswererList :list="expertList"></V-AnswererList>
        </div>-->
        <div class="hot-question">
          <div class="hq-label">热点问题</div>
          <div class="hq-items">
            <div class="hq-item" v-for="hotQuestion in hotQuestionList" :key="hotQuestion.id">
              <div class="title" @click="see(hotQuestion.id)">{{ hotQuestion.questionTitle }}</div>
              <div
                class="hq-content"
                v-if="hotQuestion.questionContent"
              >{{ hotQuestion.questionContent }}</div>
              <div class="hq-time">
                <span>
                  浏览量
                  <i class="el-icon-view"></i>
                  {{hotQuestion.scanNum}}
                </span>
                <span>
                  <i class="el-icon-time"></i>
                  {{ hotQuestion.createTime }}
                </span>
              </div>
              <hr />
            </div>
          </div>
        </div>
        <div class="team expert">
          <div class="t-label">
            <!-- <span class="team-bg"> -->
            <i class="el-icon-s-flag"></i>
            <!-- </span> -->
            <span class="Ranking">话题聚焦</span>
          </div>
          <div class="keyswodt">
            <span
              @click="keyWordSearch(item)"
              :style="item.length>=10&&{'text-align':'center','width':'100%'}"
              class="keysword"
              v-for="(item,index) in topic "
              :key="index"
            >{{item}}</span>
            <span
              v-if="topic.length==0"
              style="width:100%;text-align:center;color: rgb(149, 149, 149); font-size: 16px;"
            >暂无话题聚焦</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OneQuestion from "@/views/wisdom-answer/common/one-question/index.vue";
import AnswererList from "@/views/wisdom-answer/common/answerer-list/index.vue";
import AnswererLists from "@/views/wisdom-answer/common/answerer-lists/index.vue";
import Swiper from "@/views/wisdom-answer/index/swiper/index.vue";
import { formatDate, showTimeFormat } from "@/js/common";
import { DELETE, QUERYED, QUERY } from "@/services/dao.js";
import { post, get } from "@/services/http.js";
export default {
  data() {
    return {
      // pageNum:50,
      // pageSize:10,
      // totlePage:5000,
      // hidePageNav:true,
      teamList: [],
      expertList: [],
      questionList: [],
      hotQuestionList: [],
      topic: [] ///话题聚焦
    };
  },
  components: {
    "V-OneQuestion": OneQuestion,
    "V-AnswererList": AnswererList,
    "V-AnswererLists": AnswererLists,
    "V-Swiper": Swiper
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.searchInfo();
      // this.initTeam();
      // this.initExport();
      this.initHotQuestion();
      this.tuisong(); //问题推送
    },
    // 话题推送
    async tuisong() {
      this.topic = [];
      await get("/common/topicFocus")
        .then(e => {
          this.topic = e.data;
        })
        .catch(function(error) {});
    },
    keyWordSearch(word) {
      word = word.replace(/ /g,'');
      ////标签搜索
      this.$router.push({
        path: "/automatic",
        query: {
          word: word
        }
      });
    },
    see(id) {
      this.$router.push({
        path: "/writeanswer",
        query: {
          id: id
        }
      });
    },
    ///专业团队
    async initTeam() {
      let info = await QUERY(
        "post",
        "",
        "  WDTeamByAccept(limit: 5) {     id       teamName   anserNum     acceptNum     teamImg   }"
      );
      // info.data.WDTeamByAccept.forEach((element) => {
      //   this.teamList.push({
      //     id: element.id,
      //     img: this.api.LoginURL.concat(element.teamImg),
      //     name: element.teamName,
      //     readNum: element.anserNum,
      //     acceptNum: element.acceptNum,
      //   });
      // });
      for (let i = 0; i < info.data.WDTeamByAccept.length; i++) {
        ///团队的回答个数
        // let data = await QUERYED(  "post",    "",  ' WDMemberTeamWdAnswerRef_aggregate(where: {id: {_eq: '+info.data.WDTeamByAccept[i].id+'}}) { aggregate {   count(columns: anserNum)  }  }' );
        this.teamList.push({
          id: info.data.WDTeamByAccept[i].id,
          img: this.api.LoginURL.concat(info.data.WDTeamByAccept[i].teamImg),
          name: info.data.WDTeamByAccept[i].teamName,
          // readNum: data.data.WDMemberTeamWdAnswerRef_aggregate.aggregate.count,
          readNum: info.data.WDTeamByAccept[i].anserNum,
          acceptNum: info.data.WDTeamByAccept[i].acceptNum
        });
      }
    },
    //   //业界专家
    async initExport() {
      let info = await QUERY(
        "post",
        "",
        "  WDSpecialist (limit: 10){     id     img  name   wdAnswerNum     wdAcceptNum        }"
      );
      // info.data.WDSpecialist.forEach((element) => {
      //   this.expertList.push({
      //     id: element.id,
      //     img:this.api.LoginURL.concat( element.img),
      //     name: element.name,
      //     readNum: element.wdAnswerNum,
      //     acceptNum: element.wdAcceptNum,
      //   });
      // });
      for (let i = 0; i < info.data.WDSpecialist.length; i++) {
        //  // 回答个数
        // let data = await QUERY(
        //   "post",
        //   "",
        //   "   WDUserQuestionAnswerRef_aggregate(where: {id: {_eq: " +
        //   info.data.WDSpecialist[i].id
        //       +
        //     "}}) { aggregate {  count(columns: wdanswerref)    }  }"
        // );

        this.expertList.push({
          id: info.data.WDSpecialist[i].id,
          img: this.api.LoginURL.concat(info.data.WDSpecialist[i].img),
          name: info.data.WDSpecialist[i].name,
          // readNum:  data.data.WDUserQuestionAnswerRef_aggregate.aggregate.count,
          readNum: info.data.WDSpecialist[i].wdAnswerNum,
          acceptNum: info.data.WDSpecialist[i].wdAcceptNum
        });
      }

      //alert(info.data.WDUserQuestion_aggregate.aggregate.count)
      // this.countproblem =
      //   info.data.WDUserQuestionAnswerRef_aggregate.aggregate.count;
    },
    ///热议问题
    async initHotQuestion() {
      let info = await QUERYED(
        "post",
        "",
        "WDHotQuestion(limit:8) { scanNum    id     createTime                    questionTitle     questionContent        }"
      );
      info.data.WDHotQuestion.forEach(element => {
        this.hotQuestionList.push({
          id: element.id,
          createTime: formatDate(Date.parse(element.createTime)),
          questionTitle: element.questionTitle,
          questionContent: element.questionContent,
          scanNum: element.scanNum
        });
      });
    },
    //前一页
    // prevPage(val){
    //     this.pageNum=val-1;
    //     this.searchInfo(this.pageSize,this.pageSize*this.pageNum);
    // },
    //下一页
    // nextPage(val){
    //     this.pageNum=val-1;
    //     this.searchInfo(this.pageSize,this.pageSize*this.pageNum);
    // },
    //当前页
    // currentPage(val){
    //     this.pageNum=val-1;
    //     this.searchInfo(this.pageSize,this.pageSize*this.pageNum);
    // },
    async searchInfo() {
      let info = await QUERYED(
        "post",
        "",
        "WDNewQuestion(limit: 15)  {     id     createTime     collectNum     answerNum     keyword     questionTitle     questionContent     scanNum   } "
      );
      this.questionList.splice(0, this.questionList.length);
      info.data.WDNewQuestion.forEach(element => {
        //    let WdAnswerRef = await QUERYED(
        //   "post",
        //   "",
        //   "WdAnswerRef( where: {id: {_eq:"+element.id+" }})   {     id } "
        // );
        this.questionList.push({
          id: element.id,
          questionTitle: element.questionTitle,
          questionContent: element.questionContent,
          keyword: element.keyword,
          collectNum: element.collectNum,
          scanNum: element.scanNum,
          answerNum: element.answerNum,
          // answerNum:WdAnswerRef.data.answerNum[0].length,
          createTime: formatDate(Date.parse(element.createTime))
        });
      });
      //alert(this.questionList.length)
      // this.totlePage=info.data.WdQuestion_aggregate.aggregate.count;
    }
  }
};
</script>

<style>

@import "~@/styles/page/index.css";
.content .up {
  background-color: #ffffff;
  text-align: center;
  padding: 20px 0;
}
.slide,
.sys-intro {
  display: inline-block;
  vertical-align: top;
}
/*上-轮播 系统介绍 */
.slide {
  width: 786px;
  height: 316px;
  /* border: 1px solid red; */
}
/*上- 系统介绍 */
.sys-intro {
  width: 284px;
  height: 317px;
  margin-left: 30px;
  border: 1px solid rgba(236, 236, 236, 1);
  opacity: 0.58;
}
.sys-intro .info {
  background-color: #daecf3;
  padding: 30px 20px;
  text-align: left;
  width: 244px;
  height: 160px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: rgba(0, 0, 0, 1);
  line-height: 36px;
}
.sys-intro .group > div {
  display: inline-block;
  width: 70px;
  text-align: center;
  margin-top: 20px;
}
.sys-intro .group {
  height: 93px;
}
.sys-intro .group .answer .answer-bg {
  display: inline-block;
  width: 40px;
  height: 40px;
  background: rgba(229, 248, 248, 1);
  border-radius: 50%;
  text-align: center;
}

.sys-intro .group .answer .answer-bg .answer-icon {
  display: inline-block;
  margin-top: 7px;
  width: 26px;
  height: 26px;
  background-repeat: no-repeat;
  background-image: url(~@/assets/icon/answer.png);
}
.sys-intro .group .question .question-bg .question-icon {
  display: inline-block;
  margin-top: 7px;
  width: 26px;
  height: 26px;
  background-repeat: no-repeat;
  background-image: url(~@/assets/icon/question.png);
}
.sys-intro .group .question .question-bg {
  display: inline-block;
  width: 40px;
  height: 40px;
  background: rgba(254, 249, 225, 1);
  border-radius: 50%;
}
.sys-intro .group .question {
  margin-left: 100px;
}

.content .down {
  text-align: center;
  background-color: #f7f7f7;
  padding-top: 50px;
  padding-bottom: 100px;
}
/* 下-左 */
.content .down .left {
  vertical-align: top;
  padding-bottom: 30px;
  display: inline-block;
  width: 788px;
  background: #ffffff;
  text-align: left;
}
.content .down .left .load-more {
  margin-top: 10px;
  text-align: center;
}
.content .down .left .load-more a {
  text-decoration: none;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: rgba(0, 0, 0, 1);
}
.content .down .left .load-more a:hover {
  color: rgb(207, 36, 36);
}
.content .down .left-label {
  border-bottom: 1px solid rgba(181, 181, 181, 1);
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(4, 4, 4, 1);
  padding: 14px 28px;
}
.content .down .left .items {
  padding: 10px 28px;
  width: 92%;
  /* border: 1px solid rebeccapurple; */
  display: inline-block;
}
.sys-intro .group .answer .answer-bg .answer-icon {
  display: inline-block;
  margin-top: 7px;
  width: 26px;
  height: 26px;
  background-image: url(~@/assets/icon/answer.png);
}
.sys-intro .group .question .question-bg .question-icon {
  display: inline-block;
  margin-top: 7px;
  width: 26px;
  height: 26px;
  background-image: url(~@/assets/icon/question.png);
}
.sys-intro .group .question .question-bg {
  display: inline-block;
  width: 40px;
  height: 40px;
  background: rgba(254, 249, 225, 1);
  border-radius: 50%;
}
.sys-intro .group .question {
  margin-left: 100px;
}

.content .down {
  text-align: center;
  background-color: #f7f7f7;
  padding-top: 50px;
  padding-bottom: 100px;
}
/* 下-左 */
.content .down .left {
  vertical-align: top;
  padding-bottom: 30px;
  display: inline-block;
  width: 788px;
  background: #ffffff;
  text-align: left;
}
.content .down .left .load-more {
  margin-top: 10px;
  text-align: center;
}
.content .down .left .load-more a {
  text-decoration: none;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: rgba(0, 0, 0, 1);
}
.content .down .left .load-more a:hover {
  color: rgb(207, 36, 36);
}
.content .down .left-label {
  border-bottom: 1px solid rgba(181, 181, 181, 1);
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(4, 4, 4, 1);
  padding: 14px 28px;
}
.content .down .left .items {
  padding: 10px 28px;
  width: 92%;
  /* border: 1px solid rebeccapurple; */
  display: inline-block;
}
.left .items .item {
  border-bottom: 1px dashed rgba(181, 181, 181, 1);
  padding-bottom: 16px;
}
.content .down .right {
  text-align: left;
  vertical-align: top;
  display: inline-block;
  width: 284px;
  margin-left: 28px;
  margin-left: 30px;
}
.team {
  background-color: #ffffff;
}
.team .t-label {
  padding: 8px 13px;
  border-bottom: 1px solid rgba(181, 181, 181, 1);
  margin-bottom: 20px;
}
.team .t-label .team-bg {
  display: inline-block;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  background: rgba(229, 242, 255, 1);
  border-radius: 50%;
}
.team .t-label .team-bg .team-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-image: url(~@/assets/icon/team.png);
  margin-top: 5px;
  /* border: 1px solid red; */
  background-size: 13px 13px;
  position: relative;
  top: 4px;
  left: 3px;
}
.team .t-items {
  padding: 20px;
}
.t-items .item {
  margin-top: 10px;
}
.expert {
  margin-top: 50px;
  padding-bottom: 20px;
  /* padding-left: 20px;
  padding-right: 20px; */
}
.expert .team-bg {
  background: rgba(255, 243, 225, 1) !important;
}
.expert .t-label .team-bg .team-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url(~@/assets/icon/export.png);
  margin-top: 5px;
  background-repeat: no-repeat;
}
.hot-question {
  background-color: rgb(255, 255, 255);
  /* margin-top: 50px; */
}
.hot-question .hq-label {
  font-size: 16px;
  font-weight: 600;
  height: 50px;
  line-height: 50px;
  padding-left: 20px;
  border-bottom: 1px solid rgb(156, 156, 156);
  /* margin-bottom: 10px; */
}
.hot-question .hq-items {
  background-color: #ffffff;
  padding: 20px;
}
.hot-question .hq-items .hq-item .title {
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  cursor: pointer;
  word-wrap: break-word;
}
.hot-question .hq-items .hq-item .hq-content {
  font-size: 13px;
  line-height: 20px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /* 超过两行省略号 */
  -webkit-line-clamp: 2;
  overflow: hidden;
  word-wrap: break-word;
  height: 30px;
  line-height: 30px;
}
.hot-question .hq-items .hq-item .hq-time {
  width: 100%;
  text-align: center;
  height: 30px;
  line-height: 30px;
}
.hot-question .hq-items .hq-item {
  padding-bottom: 20px;
  /* margin-top: 20px; */
}
.hot-question .hq-items .hq-item hr {
  border: 0.5px dashed rgb(144, 148, 150);
}

.page-nav .el-pagination {
  text-align: center;
}
.bold {
  font-weight: bold;
}
.Ranking {
  width: 80px;
  height: 36px;
  font-size: 16px;
  font-weight: 600;
  font-family: Microsoft YaHei;
  color: rgba(0, 0, 0, 1);
  line-height: 36px;
  margin-left: 5px;
  position: relative;
  top: -3px;
  /* font-weight: bold; */
}
.el-icon-view {
  color: #fd6e05;
  font-weight: bold;
  font-size: 15px;
  /* background-color: violet; */
}
.el-icon-time {
  color: #0ad658;
  font-weight: bold;
  font-size: 15px;
  margin-left: 23px;
}
.el-icon-s-flag {
  font-size: 26px;
  font-weight: bold;
  color: rgb(52, 119, 244);
}
.keysword {
  display: inline-block;
  margin-bottom: 20px;
  color: rgb(9, 131, 245);
  font-weight: 500;
  background-color: rgb(205, 231, 253);
  height: 30px;
  line-height: 30px;
  padding: 2px 15px 2px 15px;
  border-radius: 17px;
  /* 超过固定宽度省略号显示 */
  max-width: 86%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 7px;
  margin-left: 7px;
  vertical-align: top;
  cursor: pointer;
}
.keyswodt {
  padding-left: 15px;
  padding-right: 15px;
}
</style>
